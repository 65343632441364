import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { global } from '../../../global.js';
import '../header/app-header.js';
import '../footer/app-footer.js';

@customElement('app-body')
export class AppBody extends LitElement {
  constructor() {
    super();
  }

  static styles = [
    global,
    css`
      :host {
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;
        min-height: 100vh;
      }
    `,
  ];

  render() {
    return html`
      <app-header></app-header>
      <slot></slot>
      <app-footer></app-footer>
    `;
  }
}

