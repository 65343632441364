import { CSSResultGroup, LitElement, css } from 'lit';
import { customElement } from 'lit/decorators.js';

// import { styles } from './style.js';

@customElement('pressence-element')
export class PressenceElement extends LitElement {
  // Default styles for all Pressence Elements
  // Mobile first, then desktop

  static styles: CSSResultGroup = css`
  `;

  scrollToID(id: string) {
    const element = this.shadowRoot?.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  sluggify(text: string) {
    return text
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '');
  }
}
