type SocialLink = {
  title: string;
  url: string;
  icon: string;
};

export const kSocialLinks: SocialLink[] = [
  {
    title: 'LinkedIn',
    url: 'https://www.linkedin.com/company/africacentreyeg/',
    icon: '/assets/media/icon-linkedin.svg',
  },
  {
    title: 'YouTube',
    url: 'https://www.youtube.com/channel/UCiDG6-Ji7sMwxIIdSduCprQ/featured',
    icon: '/assets/media/icon-youtube.svg',
  },
  {
    title: 'Instagram',
    url: 'https://www.instagram.com/africacentreyeg/',
    icon: '/assets/media/icon-instagram.svg',
  },
  {
    title: 'Facebook',
    url: 'https://www.facebook.com/AfricaCentreYEG/',
    icon: '/assets/media/icon-facebook.svg',
  },
];
