// docs for router https://github.com/thepassle/app-tools/blob/master/router/README.md

import { html } from 'lit';

if (!(globalThis as any).URLPattern) {
  await import('urlpattern-polyfill');
}

import { Router } from '@thepassle/app-tools/router.js';
import { lazy } from '@thepassle/app-tools/router/plugins/lazy.js';

// @ts-ignore
import { title } from '@thepassle/app-tools/router/plugins/title.js';

import './pages/app/app-landing.js';
import { _Event } from './data/events';

export const router = new Router({
  routes: [
    {
      path: '/',
      title: 'Enhancing Gender Equity',
      render: () => html`<app-landing></app-landing>`,
    },

    {
      path: '/about-us',
      title: 'About Us',
      plugins: [lazy(() => import('./pages/app-about/app-about.js'))],
      render: () => html`<app-about></app-about>`,
    },

    {
      path: '/events',
      title: 'Events',
      plugins: [lazy(() => import('./pages/app-events/app-events.js'))],
      render: () => html`<app-events></app-events>`,
    },
    {
      path: '/event/:slug',
      title: (params: { slug: string }) => `Event: ${params.slug}`,
      plugins: [
        lazy(() => import('./pages/app-events/event-item/event-item.js')),
      ],
      render: (params: { slug: string }) => html`<event-item
        slug="${params.slug}"
      ></event-item>`,
    },

    {
      path: '/projects',
      title: 'Projects',
      plugins: [lazy(() => import('./pages/app-projects/app-projects.js'))],
      render: () => html`<app-projects></app-projects>`,
    },
    {
      path: '/project/:slug',
      title: 'Project',
      plugins: [
        lazy(() => import('./pages/app-projects/app-project/app-project.js')),
      ],
      render: (params: { slug: string }) => html` <app-project
        .slug=${params.slug}
      ></app-project>`,
    },

    {
      path: '/resources/:slug',
      title: 'Resources',
      plugins: [lazy(() => import('./pages/app-projects/app-projects.js'))],
      render: (params: { slug: string }) => html`<app-resources slug=${params.slug}> </app-resources>`,
    },

    {
      path: '/resources',
      title: 'Resources',
      plugins: [lazy(() => import('./pages/app-projects/app-projects.js'))],
      render: () => html`<app-projects slug="all"></app-projects>`,
    },

    {
      path: '/resource/:slug',
      title: 'Resource Item',
      plugins: [
        lazy(
          () => import('./pages/app-resources/resource-item/resource-item.js')
        ),
      ],
      render: (params: { slug: string }) => html`<resource-item
        slug="${params.slug}"
      >
      </resource-item>`,
    },
    {
      path: '/supports',
      title: 'Supports',
      plugins: [lazy(() => import('./pages/app-supports/app-supports.js'))],
      render: () => html`<app-supports></app-supports>`,
    },

    {
      path: '/contact-us',
      title: 'Contact',
      plugins: [lazy(() => import('./pages/app-contact/app-contact.js'))],
      render: () => html`<app-contact></app-contact>`,
    },
  ],
});

