import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { kSocialLinks } from '../../data/social-links.js';
import { global } from '../../../global.js';

@customElement('app-footer')
export class AppFooter extends LitElement {
  @property({ type: Array }) navMenuItems: Array<any>;
  @property({ type: Array }) socialLinks = kSocialLinks;
  @property({ type: Array }) preambleMenuItems: Array<any>;
  constructor() {
    super();
    this.navMenuItems = [
      {
        title: 'Home',
        link: '/',
      },
      {
        title: 'About Us',
        link: '/about-us',
      },
      {
        title: 'Projects',
        link: '/project/initiatives-to-address-intimate-partner-violence',
      },
      {
        title: 'Events',
        link: '/events',
      },
      {
        title: 'Supports',
        link: '/supports',
      },
      {
        title: 'Volunteer',
        link: 'https://forms.monday.com/forms/266e00ea32e9cb2d23cd42934dfca251?r=use1',
      },
      {
        title: 'Contact Us',
        link: '/contact-us',
      },
    ];

    this.preambleMenuItems = [
      {
        title: 'Privacy Policy',
        link: '/privacy-policy',
      },
      {
        title: 'Terms of Service',
        link: '/events',
      },
      {
        title: 'Report an issue',
        link: '/report-an-issue',
      },
    ];
  }

  static styles = [
    global,
    css`
      section {
        flex-flow: column wrap;
        width: 90%;
      }
      section h3 {
        color: #fff;
        text-align: center;
      }
      section p {
        font-size: clamp(12px, 2.5vw, 16px);
        color: #fff;

        text-align: justify;
        text-justify: inter-word;
        padding: .5% 0;
      }

      .nav-menu {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;

        width: 100%;
        gap: 2.5%;
      }
      .nav-menu li a {
        color: #f49f30;
        font-size: clamp(16px, 2.5vw, 18px);
        font-weight: 500;
      }

      .social-menu {
        justify-content: center;
        align-items: center;
        text-align: center;

        width: 100%;
        padding: 1% 0;
      }
      .social-menu li {
        width: 5%;
      }
      .social-menu img {
        width: 25%;
        opacity: 1;
        transition: opacity 0.2s ease-in;
      }
      .social-menu img:hover {
        opacity: 0.5;
      }


      .preamble-menu {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        text-align: center;

        width: 100%;
        gap: .5%;
      }
      .preamble-menu li a {
        color: #fff;
        font-size: 1rem;
        font-weight: 600;
        position: relative;
        padding: 1rem;
        text-decoration: none;
      }

      #credits p {
        color: #fff;
        font-size: 12px;
        margin: 0;
        padding: 1% 0;
      }
      #credits a {
        color: #fff;
        font-size: 12px;
        text-decoration: none;
      }
      #credits a:hover {
        color: #ffae00;
      }
      @media (max-width: 768px) {
        .social-menu img {
          width: 75%;

        }
      }
    `,
  ];

  render() {
    return html`
      <footer>
        <section>
          <h3>Land Acknowledgement</h3>
          <p>
            We acknowledge Treaty 6 and Treaty 7 territory—the traditional and
            ancestral territory of the Cree, Dene, Blackfoot, Saulteaux and
            Nakota Sioux and the Blackfoot Confederacy: Kainai, Piikani and
            Siksika as well as the Tsuu T’ina Nation and Stoney Nakoda First
            Nation respectively. We acknowledge the many First Nations, Métis
            and Inuit who have lived in and cared for these lands for
            generations. We are grateful for the traditional Knowledge Keepers
            and Elders who are still with us today and those who have gone
            before us. As an African organization, we are mindful of the shared
            experiences of colonization, displacement, and the resilience of
            Indigenous and African communities alike. We commit to learning from
            and supporting each other's struggles for self-determination,
            justice, and equality. As guests on this land, we pledge to tread
            lightly, honor the teachings of the land, and recognize their
            enduring presence, their deep connections to the land, water, and
            all living beings, and the significant contributions they have made
            and continue to make to this territory. We make this acknowledgement
            as an act of reconciliation and gratitude to those whose territory
            we reside on or are visiting.
          </p>
        </section>

        <ul class="nav-menu">
          ${this.navMenuItems.map((item) => {
            return html`
              <li>
                <a href="${item.link}">${item.title}</a>
              </li>
            `;
          })}
        </ul>

        <ul class="social-menu">
          ${this.socialLinks.map(
            (item) => html`
              <li>
                <a href="${item.url}" target="”_blank”">
                  <img src="${item.icon}" alt="${item.title}" />
                </a>
              </li>
            `
          )}
        </ul>

        <ul class="preamble-menu">
          ${this.preambleMenuItems.map((item) => {
            return html`
              <li class="menu-item">
                <a href="${item.link}">${item.title}</a>
              </li>
            `;
          })}
        </ul>

        <div id="credits">
          <p>
            All Rights Reserved -
            <a href="https://online-presence.ca/" target="_blank">
              Online Presence
            </a>
          </p>
        </div>
      </footer>
    `;
  }
}

