import { PressenceElement } from '../../pressence-element.js';
import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../components/body/app-body.js';
import { global } from '../../../global.js';

@customElement('app-landing')
export class AppLanding extends PressenceElement {
  @property({ type: Array }) list = [
    {
      type: 'Videos',
      url: '/resources/videos',
      icon: '/assets/icons/icon-video.svg',
    },
    {
      type: 'Podcasts',
      url: '/resources/podcasts',
      icon: '/assets/icons/icon-pod.svg',
    },
    {
      type: 'Presentations',
      url: '/resources/presentations',
      icon: '/assets/icons/icon-pres.svg',
    },
    {
      type: 'Reports',
      url: '/resources/reports',
      icon: '/assets/icons/icon-reports.svg',
    },
    {
      type: 'Pamphlets',
      url: '/resources/pamphlets',
      icon: '/assets/icons/icon-pamphlets.svg',
    },
  ];

  static styles = [
    global,
    css`
      #hero {
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;

        background-image: url('/assets/photos/pic-hero.jpg');
        background-position: top 39% right 25%;
        background-repeat: no-repeat;
        width: 100%;
        padding: 7.5% 0;
        gap: 2.5%;
      }
      #hero h1 {
        text-align: center;
      }

      #tagline {
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;

        width: 50%;
        padding: max(1%, 2%);
        margin: 2.5%;
        border: 2px solid #1e592d;
      }
      #tagline h2 {
        text-align: center;
      }
      #tagline div {
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;

        width: 100%;
        padding: max(1%, 2%);
        gap: max(1%, 2%);
      }
      #tagline a button {
        margin: 2.5% 0;
      }

      #contact-menu {
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;

        width: 100%;
        background-color: #1e692d;
        padding: 2.5% 0;
      }
      #contact-menu h1 {
        text-align: center;
      }
      #contact-menu p {
        color: #fff;
        text-align: center;

        width: 85%;
      }
      #contact-menu h2 {
        color: #fff;
      }
      #contact-menu span {
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;

        width: 100%;
        gap: 7.5%;
      }
      #contact-menu div {
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;

        width: 25%;
        height: 25rem;
        border: 2px solid #f49f30;
        margin: 2%;
      }
      #contact-menu img {
        width: 35%;
      }

      #projects {
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;

        width: 100%;
        padding: 5% 0;
        background-image: url('/assets/photos/pic3.jpg');
        background-repeat: no-repeat;
        background-position: top 45% right 30%;
      }
      #title {
        width: 35%;
      }
      #title h1 {
        color: #1e592d;
        text-align: start;
      }
      .projects-menu {
        justify-content: center;
        align-items: center;
        text-align: center;

        width: 50%;
        padding: 1%;
        gap: 2.5%;
        border: 2px solid #243e90;
      }
      .projects-menu li {
        width: 35%;
      }
      .projects-menu li img {
        width: 30%;
      }
      .projects-menu li h4 {
        color: #fff;
        font-weight: 500;

        transition: 0.2s ease-in;
      }
      .projects-menu li h4:hover {
        color: #f49f30;
      }

      @media (max-width: 768px) {
        #hero {
          flex-flow: column wrap;
          justify-content: center;
          align-items: center;

          background-image: url('/assets/photos/pic-hero.jpg');
          background-position: top 50% left 55%;
          background-repeat: no-repeat;
          background-size: cover;
        }
        #tagline {
          flex-flow: column wrap;
          justify-content: center;
          align-items: center;

          width: 75%;
        }
        #tagline a button {
          padding: 0.5rem 1rem;
        }

        #contact-menu span {
          flex-flow: column wrap;
          justify-content: center;
          align-items: center;

          width: 100%;
          padding: 2.5% 0;
          gap: 7.5%;
        }
        #contact-menu div {
          flex-flow: column wrap;
          justify-content: center;
          align-items: center;

          width: 75%;
        }

        #projects {
          flex-flow: column wrap;
        }
        #title {
          width: 100%;
        }
        .projects-menu {
          flex-flow: column wrap;

          width: 75%;
          gap: 5%;
        }
        .projects-menu li {
          width: 75%;
        }
      }
    `,
  ];

  render() {
    return html`
      <app-body>
        <main>
          <section id="hero">
            <h1>the Enhancing Gender Equity Program</h1>

            <span id="tagline">
              <h2>
                Empowering women and girls for equitable opportunity, lasting
                solutions, and stronger communities.
              </h2>
              <div>
                <a href="/about-us">
                <button id="about">Learn More</button>
                </a>

                <a href="https://forms.monday.com/forms/266e00ea32e9cb2d23cd42934dfca251?r=use1" target="_blank">
                  <button id="volunteer">Volunteer</button>
                </a>
              </div>
            </span>
          </section>

          <section id="contact-menu">
            <h1>Stay Connected with Us</h1>
            <span>
              <div>
                <a href="/events"><h2>Events</h2></a>
                <img src="/assets/icons/icon-events.svg" />
                <p>
                  Explore topics such as cultivating healthy relationships,
                  fostering inclusive leadership, and creating safe spaces for
                  marginalized communities. Contribute your voice and join the
                  conversation.
                </p>
              </div>

              <div>
                <a href="/supports"><h2>Supports</h2></a>
                <img src="/assets/icons/icon-supports.svg" />
                <p>
                  Connect with local organizations, networks, and support groups
                  to find mentorship programs, opportunities, and initiatives
                  that promote inclusivity in various sectors near you.
                </p>
              </div>
            </span>
          </section>

          <section id="projects">
            <a id="title" href="/project/initiatives-to-address-intimate-partner-violence">
            <h1>Initiatives to Address Intimate Partner Violence</h1>
            </a>
            <menu class="projects-menu">
              ${this.list.map(
                (item) => html`
                <li>
                <a href="${item.url}" class="project-link">
                <img src="${item.icon}" alt="Blog Post Image" />
                <h4>${item.type}</h4>
                </a>
                </li>
                `
              )}
            </menu>
          </section>

        </main>
      </app-body>
    `;
  }
}

