import { css, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { global } from '../../../global.js';
import { PressenceElement } from '../../pressence-element.js';

@customElement('app-header')
export class AppHeader extends PressenceElement {
  @state() menuItems: Array<any>;
  constructor() {
    super();
    this.menuItems = [
      {
        title: 'About Us',
        link: '/about-us',
        dropdown: false,
      },
      {
        title: 'Projects',
        link: '/projects',
        dropdown: true,
        submenuItems: [
          {
            title: 'Initiatives to Address IPV',
            link: '/project/initiatives-to-address-intimate-partner-violence',
          },
        ],
      },
      {
        title: 'Events',
        link: '/events',
        dropdown: false,
      },
      {
        title: 'Supports',
        link: '/supports',
        dropdown: true,
        submenuItems: [
          {
            title: 'Edmonton',
            link: '/supports#edmonton',
          },
          {
            title: 'Red Deer',
            link: '/supports#red-deer',
          },
          {
            title: 'Calgary',
            link: '/supports#calgary',
          },
        ],
      },
      {
        title: 'Get In Touch',
        link: '/contact-us',
        dropdown: true,
        submenuItems: [
          {
            title: 'Volunteer',
            link: 'https://forms.monday.com/forms/266e00ea32e9cb2d23cd42934dfca251?r=use1',
          },
          {
            title: 'Contact Us',
            link: '/contact-us',
          },
        ],
      },
    ];
  }

  static styles = [
    global,
    css`
      :host {
        z-index: 100;
      }

      #logo {
        width: clamp(150px, 10%, 475px);
      }
      #menu {
        display: none;
        width: clamp(50px, 5%, 75px);
        border: none;
      }
      #menu:hover {
        background-color: transparent;
      }
      #logo {
        width: 5%;
      }

      .nav-menu {
        flex-flow: row wrap;
        justify-content: center;
        align-items: start;
        text-align: center;

        width: 75%;
      }
      .nav-menu li {
        width: 15%;
        position: relative;
      }
      .nav-menu li a {
        color: #243e90;
        font-size: clamp(24px, 5vw, 30px);
        font-weight: 700;

        width: 100%;
        transition: 0.2s ease-in-out;
      }
      .nav-menu li a:hover {
        color: #1e592d;
      }

      .has-dropdown > .sub-menu {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: calc(100% + 0.5vw);
        background-color: #fff;
        border: 2.5px solid #243e90;
        transition: all 0.1s ease;
        list-style: none;
      }
      .has-dropdown:hover > .sub-menu {
        opacity: 1;
        visibility: visible;
        top: calc(100% + 0.5vw);
      }
      .sub-menu {
        flex-flow: column wrap;
        width: 100%;
      }
      .sub-menu li {
        text-align: start;

        width: 95%;
        padding: 5%;
      }
      .sub-menu li > a {
        color: #000;
        font-size: 20px;
        font-weight: 500;

        transition: 0.2s ease-in-out;
      }
      .sub-menu li > a:hover {
        color: #f49f30;
      }

      .mobile-menu {
        visibility: hidden;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;

        width: 100%;
        background-color: #00000095;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
      .menu {
        flex-flow: column wrap;
        justify-content: start;
        align-items: center;

        width: 75%;
        gap: 2.5%;
      }
      .menu li a {
        color: #243e90;
        text-align: start;
        font-size: clamp(24px, 7.5vw, 36px);
        font-weight: 600;
      }
      .menu li a:hover {
        color: #f49f30;
      }
      .menu button {
        width: 10%;
        border: none;
        padding: 10% 0;
      }
      .menu button:hover {
        border: none;
        background-color: transparent;
      }
      .menu button:active {
        transform: rotate(0.25turn);
      }

      @media (max-width: 768px) {
        .nav-menu {
          display: none;
        }
        #menu {
          display: flex;
        }
        header {
          padding: 2.5% 0;
        }
      }
    `,
  ];

  render() {
    return html`
      <header>
        <a href="/" id="logo"><img src="/assets/brand-kit/icon-AC.svg" /></a>

        <ul class="nav-menu">
          ${this.menuItems.map((item) => {
            return html`
              <li class="${item.dropdown ? 'has-dropdown' : ''}">
                <a href="${item.link}">${item.title}</a>
                ${item.dropdown
                  ? html`
                      <ul class="sub-menu">
                        ${item.submenuItems.map(
                          (subitem: {
                            link: string;
                            title: string;
                            target: string;
                          }) => {
                            return html`
                              <li>
                                <a
                                  href="${subitem.link}"
                                  target="${subitem.target
                                    ? subitem.target
                                    : '_self'}"
                                >
                                  ${subitem.title}</a
                                >
                              </li>
                            `;
                          }
                        )}
                      </ul>
                    `
                  : ''}
              </li>
            `;
          })}
        </ul>
      </header>
      <section class="mobile-menu">
        <ul class="menu">
          ${this.menuItems.map((item) => {
            return html`
              <li>
                <a href="${item.link}">${item.title}</a>
              </li>
            `;
          })}
          <button><img src="/assets/icons/icon-exit.svg" /></button>
        </ul>
      </section>
    `;
  }
}

