import { css } from 'lit';
export const global = css`
  a {
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    width: 100%;
    transition: 0.3s ease-in-out;
  }

  h1 {
    color: #F49F30;
    font-size: clamp(30px, 10vw, 48px);
    font-weight: 600;
    text-align: center;

    margin: 0;
    padding: 0;
  }
  h2 {
    color: #243E90;
    font-size: clamp(24px, 5vw, 32px);

    margin: 0;
    padding: 0;
  }
  h3 {
    color: #000;
    font-size: clamp(20px, 2.5vw, 24px);

    margin: 0;
    padding: 0;
  }
  h4 {
    color: #1e592d;
    font-size: clamp(20px, 2.5vw, 24px);

    margin: 0;
    padding: 0;
  }

  p {
    color: #000;
    font-size: clamp(16px, 5vw, 20px);

    margin: 0;
    padding: 0;
  }

  header {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;

    background-color: #f49f30;
    width: 100%;
  }
  nav {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;

    width: 85%;
  }
  footer {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;

    width: 100%;
    background-color: #243e90;
    padding: 1% 0;
  }
  main {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;

    width: 100%;
  }

  section, article,
  div, span,
  dialog, form
  {
    display: flex;
  }

  label {
    font-family: 'League Spartan', sans-serif;
    font-size: clamp(16px, 5vw, 20px);
    font-weight: 600;
    color: #3f1600;
  }
  input {
    font-family: 'League Spartan', sans-serif;
    font-size: clamp(16px, 5vw, 18px);
    width: 100%;
    padding: 0.5%;
    border: 2px solid #e6e6e6;
  }
  textarea {
    font-family: 'League Spartan', sans-serif;
    font-size: clamp(16px, 5vw, 18px);
    width: 100%;
    height: 10rem;
    padding: 1% 0.5%;
    border: 2px solid #e6e6e6;
  }
  textarea {
    font-family: 'League Spartan', sans-serif;
    font-size: clamp(16px, 5vw, 18px);
    width: 100%;
    height: 10rem;
    padding: 1% 0.5%;
    border: 2px solid #e6e6e6;
  }

  ul,
  ol,
  menu {
    display: flex;

    margin: 0;
    padding: 0;
    list-style: none;
  }

  img {
    width: 100%;
  }

  button {
    color: #fff;
    padding: 1rem 2rem;
    font-family: 'League Spartan', sans-serif;
    font-size: clamp(18px, 2.5vw, 24px);
    font-weight: 400;
    text-decoration: none;
    border: none;
    background-color: #1e592d;

    transition: 0.3s ease-in-out;
    cursor: pointer;
  }
  button:hover {
    background-color: #f49f30;
  }
`;
